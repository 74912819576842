import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetService } from "./useGetService";
import {
  API,
  DashboardTabs,
  ETransportationServiceRequestTaskType,
} from "../constants";

// Third party
// import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { usePostService } from "./usePostService";
import { toast } from "react-toastify";
import {
  EServiceRequestCategory,
  EServiceRequestType,
  IServiceRequest,
  ITMDetail,
} from "../types/global.types";

export const useAdminDataGrid = (
  startData: Date | string,
  endDate: Date | string,
  selectedTab: string
) => {
  const { postData, isSubmitting } = usePostService();

  const [rows, setRows] = useState([]);
  const [isRowsLoading, setRowsLoading] = useState(false);

  // API CALL
  const { response, isLoading, refetch } = useGetService(
    `${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${selectedTab}`
  );

  // FETCH ASSIGNMENT
  const {
    response: serviceRequests,
    isLoading: isAssignedDataLoading,
    refetch: serviceRequestRefetch,
  } = useGetService(`${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}`);

  // FETCH CREATED MANUAL REQUEST DETAILS
  const {
    response: manualRequestDetails,
    isLoading: ismanualRequestDetailsLoading,
  } = useGetService(`${API.VENDOR_SERVICE_REQUEST.CREATE_MANUAL_REQUEST}s`);

  const isDeparture = selectedTab === DashboardTabs.DEPARTURE;
  const assignedData = useMemo(() => {
    if (
      isAssignedDataLoading ||
      !Array.isArray(serviceRequests?.data) ||
      !response?.data
    ) {
      return { data: [] };
    }

    let currentAssignedData: any[] = [];
    response.data.forEach((x: any) => {
      serviceRequests.data.forEach((y: any) => {
        if (x.FLIGHTDATA?.EMP_ID === y.tmId) {
          currentAssignedData.push(y);
        }
      });
    });

    const filteredData = currentAssignedData.filter((x: IServiceRequest) =>
      isDeparture
        ? x.requestCategory === EServiceRequestCategory.DEPARTURE
        : x.requestCategory === EServiceRequestCategory.ARRIVAL
    );

    return { data: filteredData };
  }, [response, serviceRequests, isAssignedDataLoading, isDeparture]);

  useEffect(() => {
    let interval = setInterval(() => {
      serviceRequestRefetch(
        `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}`
      );
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getArrivedInfo = useCallback(
    (tm: ITMDetail) => {
      if (isAssignedDataLoading || !assignedData?.data) return false;

      const isTmArrived = assignedData?.data.some((serviceRequest: any) => {
        return (
          serviceRequest.requestType ===
            EServiceRequestType.SHIPBOARD_TM_ONBOARDING &&
          serviceRequest.tmId === tm.FLIGHTDATA.EMP_ID
        );
      });

      return isTmArrived;
    },

    [assignedData, isAssignedDataLoading]
  );

  const getUnManifestedTmScans = useCallback(() => {
    const unManifestedTms: any[] = [];
    if (isAssignedDataLoading || !assignedData?.data) return [];

    assignedData?.data?.forEach((serviceRequest: IServiceRequest) => {
      if (!serviceRequest.isUnManifested) return;

      const unManifestedTm = {
        id: serviceRequest.tmId,
        manualRequestId: serviceRequest.id,
        SCH_ARVL_DT: serviceRequest.arrivalDate,
        SCH_ARVL_TIME: serviceRequest.arrivalTime
          ? serviceRequest.arrivalTime
          : "",
        EMP_ID: serviceRequest.tmId,
        isUrgentTransportRequest: false,
        FULL_NAME: serviceRequest.tmName || "Nil",
        DEPT: "Nil",
        HOTEL_NAME: "Nil",
        empName: "",
        Name: serviceRequest.tmName,
        assignedInfo: [serviceRequest],
        Ship: {
          Name: "Nil",
        },
        FlagsOut: [],
        Port: {},
        isMeet_and_greetScanned: false,
        isTransportationScanned: false,
        isHotelScanned: false,
        isPortAgent: false,
        isArrivedToShip: getArrivedInfo({
          FLIGHTDATA: { EMP_ID: serviceRequest.tmId },
        } as ITMDetail),
      };

      unManifestedTms.push(unManifestedTm);
    });

    return unManifestedTms;
  }, [assignedData, isAssignedDataLoading, getArrivedInfo]);

  const dataHandling = useCallback(
    (resData: ITMDetail[]) => {
      let newData: any = [];
      setRowsLoading(true);
      if (resData.length > 0) {
        const data = _.cloneDeep(resData);
        data.forEach((x) => {
          const y = {
            ...x.FLIGHTDATA,
            ...x.HOTELDATA,
            ...x.PROFILEDATA,
            ...x.Assignments,
            isMeet_and_greetScanned: false,
            isTransportationScanned: false,
            isHotelScanned: false,
            isPortAgent: false,
            isArrivedToShip: getArrivedInfo(x),
            id: x.FLIGHTDATA.EMP_ID, // uuidv4(),
            isManualRequestCreated:
              (!ismanualRequestDetailsLoading &&
                manualRequestDetails?.data &&
                manualRequestDetails?.data.some(
                  (v: any) => v.tmId === x.FLIGHTDATA.EMP_ID
                )) ||
              false,
            assignedInfo:
              (!isAssignedDataLoading &&
                assignedData?.data &&
                assignedData?.data.filter(
                  (v: any) => v.tmId === x.FLIGHTDATA.EMP_ID
                )) ||
              [],
          };
          newData.push(y);
        });
      }

      setRowsLoading(false);

      const unManifestedTms = getUnManifestedTmScans();

      return [...unManifestedTms, ...newData];
    },
    [
      assignedData?.data,
      isAssignedDataLoading,
      ismanualRequestDetailsLoading,
      getUnManifestedTmScans,
      getArrivedInfo,
      manualRequestDetails?.data,
    ]
  );

  useEffect(() => {
    setRowsLoading(isLoading || isAssignedDataLoading);
    isLoading === false &&
      response &&
      response.data.length > 0 &&
      setRows(dataHandling(response.data) as any);
  }, [response, isLoading, isAssignedDataLoading, dataHandling]);

  const refetchData = (seltab: string) => {
    setRows([]);
    refetch(`${API.ADMIN.LIST_ARRIVALS_AND_DEPARTURE}?src=${seltab}`);
    serviceRequestRefetch(
      `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}`
    );
  };

  const handleArriveClickedCall = async (payload: any) => {
    try {
      await postData(API.CARNIVAL_ADMIN.CREATE_SERVICE_REQUEST, payload);

      toast.success("Acknowledged successfully by shipboard! ");
      const dataIndex = rows.findIndex(
        (x: any) => x.EMP_ID === payload.assignedDetails?.[0]?.tmId
      );
      const currentData = [...rows] as any;
      currentData[dataIndex].isArrivedToShip = true;
      setRows(currentData);
    } catch (err) {
      console.error(err);
      toast.error("Failed!");
    }
  };

  const handleCreateManualRequest = (resInfo: any) => {
    const data = [...rows];
    data.map((x: any) => {
      x.isManualRequestCreated =
        x.isManualRequestCreated === false
          ? resInfo.data.some((y: any) => y.tmId === x.EMP_ID)
          : x.isManualRequestCreated;
      return x;
    });
    setRows(data);
    console.log("handleCreateManualRequest", data, resInfo);
  };

  return {
    rows,
    isRowsLoading,
    refetchData,
    assignedData,
    // transportAssignedCount,
    // transportationScannedCount,
    // hotelScannedCount,
    // portScannedCount,
    // shipboardArrivedCount,
    handleArriveClickedCall,
    handleCreateManualRequest,
  };
};
