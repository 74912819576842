import { useEffect, useState } from "react";
import { useGetService } from "./useGetService";
import { API, EServiceRequestStatus } from "../constants";
import { getUserData } from "../shared/utils";

import _ from "lodash";

export const useScannedDetails = () => {
  const [rows, setRows] = useState([]);

  const vendorId = getUserData()["cognito:username"];

  const { response, isLoading } = useGetService(
    `${API.CARNIVAL_ADMIN.GET_SERVICE_REQUEST_BY_VENDOR_ID}?vendorId=${vendorId}`
  );

  useEffect(() => {
    isLoading === false &&
      response?.data.length > 0 &&
      setRows(
        response.data.filter((d: any) => {
          return d.status === EServiceRequestStatus.COMPLETED;
        })
      );
  }, [response, isLoading]);

  return {
    rows,
    isRowsLoading: isLoading,
  };
};
