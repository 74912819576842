import { Link, useLocation } from "react-router-dom";
import { ScopeRouteType } from "../../routes";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";

type CustomMenuItemProps = {
  scopeRoute: ScopeRouteType;
  userData: any;
  state: any;
  alertCount?: any;
};
export const CustomMenuItem = ({
  scopeRoute,
  userData,
  state,
  alertCount,
}: CustomMenuItemProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  console.log("scopeRoute", scopeRoute);
  const location = useLocation();
  const pathName = location.pathname;

  const handleClick = (event: any) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  return (
    <>
      {!state.openSidebar && scopeRoute.subMenus?.length && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
          className="sub-menu-container-close-mode"
        >
          {scopeRoute.subMenus?.map((x) => {
            return (
              <MenuItem
                onClick={handleClose}
                className={`left-section-text-color ${
                  pathName === x.path ? "selected-link" : ""
                }`}
              >
                <Link to={x.path} className="link-style">
                  <span
                    className={pathName === x.path ? "selected-icon" : "icon"}
                  >
                    {x.icon ? x.icon : ""}
                  </span>
                  <span
                    className={pathName === x.path ? "text nav-text" : "text"}
                  >
                    {x.label ? x.label : ""}
                  </span>
                </Link>
              </MenuItem>
            );
          })}
        </Menu>
      )}
      <li
        aria-owns={anchorEl ? "simple-menu" : undefined}
        onClick={handleClick}
        onMouseOver={handleClick}
        className={`nav-link ${
          scopeRoute.notvisible !== undefined &&
          scopeRoute.notvisible.includes(userData?.["custom:vendorType"])
            ? "hide"
            : ""
        }`}
        key={scopeRoute.path}
      >
        <Link
          to={scopeRoute.path}
          className={`left-section-text-color ${
            pathName === scopeRoute.path && scopeRoute.subMenus === undefined
              ? "selected-link"
              : ""
          }`}
        >
          <span
            className={
              pathName === scopeRoute.path && scopeRoute.subMenus === undefined
                ? "selected-icon"
                : "icon"
            }
          >
            {scopeRoute.icon ? scopeRoute.icon : ""}
          </span>
          <span className="text nav-text">
            {scopeRoute.label ? scopeRoute.label : ""}
          </span>
          {scopeRoute.count && scopeRoute.label === "Alerts" && (
            <span className="text count-text">
              {alertCount?.length || scopeRoute.count}
            </span>
          )}

          {scopeRoute.count && scopeRoute.label === "Vendor requests" && (
            <span className="text count-text">
              {state?.vendorRequestCount || scopeRoute.count}
            </span>
          )}
        </Link>
      </li>
    </>
  );
};
