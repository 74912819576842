import { MGButton, SearchInput } from "../../../components";

// Third party
import { FaFileInvoiceDollar } from "react-icons/fa6";

// Style
import "./GenerateInvoice.scss";

export const GenerateInvoiceToolbar = ({
  rows,
  handleGenerate,
  isSelecteItemForGenerate,
  searchParams,
  handleSearchParam,
  isSubmitting,
}: any) => {
  return (
    <div className="generate-invoice-toolbar">
      {rows && rows.length > 0 && (
        <>
          {!!handleSearchParam && (
            <SearchInput
              className="search-field"
              placeholder="Search crew id..."
              value={searchParams}
              onChange={handleSearchParam}
            />
          )}
          <MGButton
            className="model-button"
            onClick={handleGenerate}
            disabled={isSelecteItemForGenerate || isSubmitting}
          >
            <FaFileInvoiceDollar className="dollar-icon" />
            {isSubmitting ? "Generating..." : "Generate"}
          </MGButton>
        </>
      )}
    </div>
  );
};
