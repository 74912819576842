import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// INTERNAL EXPORT
import "./TopHeader.scss";
import air_logo from "../../resources/images/air_logo.png";

// components import
import { Popper } from "../";
import { UserProfilePopper } from "./UserProfilePopper";

// utils
import { getUserData } from "../../shared/utils";
import { API, UserRole, VendorTypeService } from "../../constants";
import { ActionTypes, GlobalContextData } from "../../context";
import { useGetService } from "../../hooks";

export const TopHeader = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContextData);

  const {
    response: notificationResponse,
    refetch: refetchNotification,
    error: refetchNotificationError,
  } = useGetService(
    getUserData() &&
      getUserData()["cognito:username"] &&
      `${API.NOTIFICATIONS.GET}?createdForUserId=${
        getUserData()["cognito:username"]
      }`
  );

  useEffect(() => {
    setUserData(getUserData());
    dispatch({
      type: ActionTypes.SET_LOGGED_IN_USER_PROFILE_DETAILS,
      payload: madeReadableUserInfo(getUserData()),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("notificationResponse", notificationResponse);

  useEffect(() => {
    if (getUserData()["cognito:username"]) {
      let interval = setInterval(() => {
        refetchNotification(
          `${API.NOTIFICATIONS.GET}?createdForUserId=${
            getUserData()["cognito:username"]
          }`
        );
      }, 15000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_INCOMING_NOTIFICATIOS,
      payload: notificationResponse?.data?.notifications,
    });
  }, [notificationResponse?.data?.notifications]);

  const madeReadableUserInfo = (user: any) => {
    return {
      ...user,
      role: user["custom:roleName"] || undefined,
      vendorType: user["custom:vendorType"] || undefined,
    };
  };

  console.log("userData", userData, state);

  const handledClickedItem = () => {
    setShowDropdown(false);
    if (userData["custom:roleName"] === UserRole.VENDOR) {
      if (userData["custom:vendorType"] === VendorTypeService.HOTEL_ONLY) {
        navigate(`hotel/profile/${userData["cognito:username"]}`);
      }

      if (userData["custom:vendorType"] === VendorTypeService.MEET_GREET) {
        navigate(`meet-greet/profile/${userData["cognito:username"]}`);
      }

      if (
        userData["custom:vendorType"] ===
        VendorTypeService.TRANSPORTATION_MEET_GREET
      ) {
        navigate(
          `transportation-meet-greet/profile/${userData["cognito:username"]}`
        );
      }

      if (
        userData["custom:vendorType"] === VendorTypeService.TRANSPORTATION_ONLY
      ) {
        navigate(`transport/profile/${userData["cognito:username"]}`);
      }

      if (userData["custom:vendorType"] === VendorTypeService.SHIPBOARD_HR) {
        navigate(`shipboard-hr/profile/${userData["cognito:username"]}`);
      }

      return;
    }
    navigate("/user-profile");
  };

  if (
    refetchNotificationError &&
    (refetchNotificationError as unknown as string) ===
      "Failed to authenticate token."
  ) {
    navigate("/");
  }

  return (
    <section className="top-header-container">
      <div className="top-left-section">
        <a href={"/dashboard"}>
          <img
            src={air_logo}
            height="50px"
            width="50px"
            alt="logo"
            className="logo-sec"
          />
        </a>
        <h1 className="header-text">Crew Transportation System</h1>
      </div>

      <div className="top-header-right">
        {/* <div
          style={{ marginTop: ".5rem", cursor: "pointer" }}
          onClick={() => navigate("/alerts")}
        >
          {alertCount && (
            <Badge color="primary" badgeContent={alertCount.length} max={10}>
              <SVGNotification />
            </Badge>
          )}
        </div> */}
        <div>
          <div
            className="icon-container"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {userData?.name?.charAt(0).toUpperCase() ||
              userData?.email?.charAt(0).toUpperCase() ||
              ""}
          </div>
        </div>
        <Popper
          handleClickOutside={() => setShowDropdown(false)}
          className="user-popper"
          show={showDropdown}
        >
          <UserProfilePopper
            handledClickedItem={handledClickedItem}
            firstName={userData?.name}
            lastName={""}
            email={userData?.email}
            vendorType={userData && userData["custom:vendorType"]}
            role={userData && userData["custom:roleName"]}
          />
        </Popper>
      </div>
    </section>
  );
};
