export enum UserRole {
  VENDOR = "vendor",
  SUPER_ADMIN = "superAdmin",
  CARNVAL_ADMIN = "admin",
}

// TODO - This is not service, its an enum. The name should be updated
export enum VendorTypeService {
  MEET_GREET = "Meet & Greet",
  TRANSPORTATION_ONLY = "Transportation",
  TRANSPORTATION_MEET_GREET = "Transportation & Meet/Greet",
  HOTEL_ONLY = "Hotel",
  SAFEGUARD = "Safeguard",
  PORT_AGENT = "Port Agent",
  SHIPBOARD_HR = "Shipboard HR",
  TM_CENTER = "TM Center",
  CBP = "CBP",
  AGENCY_MANILA = "Agency Manila",
  AGENCY_INDONESIA = "Agency Indonesia",
}

export enum EServiceRequestStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
}

export enum DashboardTabs {
  ARRIVALS = "ARRIVALS",
  DEPARTURE = "DEPARTURE",
  PORT = "PORT",
  TRANSPORTATION_REQUEST = "TRANSPORTATION_REQUEST",
  MANUAL_REQUEST = "MANUAL_REQUEST",
  HOTEL_REQUEST = "HOTEL_REQUEST",
}

export enum NotificationsTabs {
  INBOX = "INBOX",
  SENT = "SENT",
}

export enum ETransportationServiceRequestTaskType {
  AIRPORT_TO_HOTEL = "airportToHotel",
  AIRPORT_TO_PORT = "airportToPort",
  HOTEL_TO_AIRPORT = "hotelToAirport",
  HOTEL_TO_PORT = "hotelToPort",
  PORT_TO_HOTEL = "portToHotel",
  PORT_TO_AIRPORT = "portToAirport",
  MEET_AND_GREET = "meetAndGreet",
  HOTEL = "hotel",
  SHIPBOARD_TM_ONBOARDING = "shipboardTMOnboarding",
}

export enum ENotificationsType {
  ASSIGNED = "assigned",
  MANUAL_REQUEST = "manual request",
  WEATHER_ALERTS = "weather alerts",
}

export enum EHotelServiceRequestType {
  BREAKFAST = "BREAKFAST",
  LUNCH = "LUNCH",
  DINNER = "DINNER",
}

export enum EInvoicingType {
  DRAFT = "Draft",
  ACTIVE = "Active",
  ARCHIVE = "Archive",
}
