import { useContext, useMemo, useState } from "react";

// Third party
import { DataGrid } from "@mui/x-data-grid";

// style
import "./GenerateInvoice.scss";
import { usePostService, useScannedDetails } from "../../../hooks";
import { GenerateInvoiceToolbar } from "./GenerateInvoiceToolbar";
import { API, EInvoicingType } from "../../../constants";
import { GlobalContextData } from "../../../context";
import { useNavigate } from "react-router-dom";

export const GenerateInvoice = () => {
  const { state } = useContext(GlobalContextData);
  const navigate = useNavigate();

  const [selectedData, setSelectedData] = useState<Array<any>>([]);
  const { rows, isRowsLoading } = useScannedDetails();
  const [searchParams, setSearchParams] = useState("");
  const { postData, isSubmitting } = usePostService();

  const handleRowSelectionModelChange = (data: any) => {
    setSelectedData(data);
  };

  const columnHeaderData = useMemo(() => {
    return [
      {
        field: "tmId",
        headerName: "Crew Id",
        flex: 1,
      },
      {
        field: "arrivalDate",
        headerName: "Arrival date/time",
        flex: 1.5,
        valueGetter: (
          value: any,
          row: { arrivalDate: any; arrivalTime: any }
        ) => {
          return `${row.arrivalDate || ""} ${row.arrivalTime || ""}`;
        },
      },
      {
        field: "empName",
        headerName: "Assigned name",
        flex: 1,
        valueGetter: (value: any, row: any) => {
          return row.empName;
        },
      },
      {
        field: "empEmail",
        headerName: "Assigned email",
        flex: 1,
        valueGetter: (value: any, row: any) => {
          return row.empEmail;
        },
      },
    ];
  }, []);

  const handleSearchParam = (text: string) => {
    setSearchParams(text);
  };

  const rowData = useMemo(() => {
    let data = [...rows];
    if (searchParams) {
      data = rows?.filter((item: any) => {
        return item.tmId?.includes(searchParams);
      });
    }
    return data;
  }, [searchParams, rows]);

  const handleGenerate = async () => {
    const payload = {
      invoiceRequest: [
        {
          vendorId: state.userInfo["cognito:username"],
          status: EInvoicingType.DRAFT,
          amountDue: "5001",
          tmIds: selectedData,
          serviceCostDetails: [
            // {
            //   postDate: "",
            //   description: "",
            //   comment: "",
            //   amount: "",
            //   id: uuidv4(),
            // },
          ],
        },
      ],
    };
    try {
      const res = await postData(
        `${API.INVOICE.CREATE}`,
        {
          ...payload,
        },
        "POST"
      );
      if (res.data[0].id) {
        navigate(`/invoicing/${res.data[0].id}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className="invoicing-details-container">
      <DataGrid
        rows={rowData || []}
        sx={{ height: rows.length === 0 ? 200 : "auto", marginTop: 2 }}
        columns={columnHeaderData || []}
        loading={isRowsLoading}
        slots={{
          toolbar: GenerateInvoiceToolbar,
        }}
        slotProps={
          {
            toolbar: {
              rows: rows,
              searchParams,
              handleSearchParam: handleSearchParam,
              isSelecteItemForGenerate: selectedData.length === 0,
              handleGenerate: handleGenerate,
              isSubmitting: isSubmitting,
            },
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "skeleton",
            },
          } as any
        }
        checkboxSelection
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={selectedData}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        hideFooter={[].length === 0}
      />
    </section>
  );
};
