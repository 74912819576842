import { useContext, useMemo, useState } from "react";
import { Grid, MGButton, SearchInput, SelectDropdown } from "../../components";
// import { useHandleTraveller } from "../../hooks/useHandleTraveller";
import { FilterSet } from "../Dashboard//VendorDashboard/FilterSet";
import "./Invoicing.scss";
// import { Tabs } from "../Dashboard/VendorDashboard";
import _ from "lodash";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { GlobalContextData } from "../../context";
import { useLocation, useNavigate } from "react-router-dom";

type InvoicingType = {
  children: React.ReactNode;
};

export const Invoicing = (props: InvoicingType) => {
  const { state } = useContext(GlobalContextData);
  const location = useLocation();
  const pathName = location.pathname;

  console.log("props", props);

  return <section className="invoicing-container">{props.children}</section>;
};
