import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

// Import react Icon
import { AiOutlineRight } from "react-icons/ai";

// Scoped routes
import { ScopeRouteType } from "../../routes/MainRoutes";

// internal
import { ActionTypes, GlobalContextData } from "../../context";

// Style
import "./LeftNavBar.scss";
import { useScopedRoutes } from "../../hooks";
import { getUserData } from "../../shared/utils";
import { UserRole, VendorTypeService } from "../../constants";
import { CustomMenuItem } from "./CustomMenuItem";
import { SubMenuItem } from "./SubMenuItem";
import { Popover } from "@mui/material";
// import SVGMessage from "../../resources/temp/message";

// const messages = [
//   "Flight Emirates EK449 is delayed by 2 hours. Crew 110023 and 012399 are impacted and may check in later than expected",
// ];

export const LeftNavBar = () => {
  const { leftMenuScopedRoutes } = useScopedRoutes();
  const isVendor = getUserData()?.["custom:roleName"] === UserRole.VENDOR;
  const isHotelVendor =
    getUserData()?.["custom:vendorType"] === VendorTypeService.HOTEL_ONLY;

  const { state, dispatch } = useContext(GlobalContextData);

  const location = useLocation();

  const alertCount = state.inComingNotification?.filter(
    (x: any) => x.read === false
  );

  console.log("state", state);

  return (
    <nav className={`sidebar ${state.openSidebar ? "" : "close"}`}>
      <header>
        <div
          className="toggle"
          onClick={() =>
            dispatch({
              type: ActionTypes.OPEN_CLOSE_SIDE_BAR,
              payload: !state.openSidebar,
            })
          }
        >
          <AiOutlineRight />
        </div>
      </header>

      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            {leftMenuScopedRoutes &&
              Object.keys(leftMenuScopedRoutes).map(
                (groupKey: string, index) => {
                  return (
                    <div key={groupKey + "" + index} className="content-height">
                      {index > 0 && <hr className="horizontal-line" />}
                      {state.openSidebar && groupKey && (
                        <div className="menu-group">{groupKey}</div>
                      )}
                      {leftMenuScopedRoutes[groupKey].map(
                        (scopeRoute: ScopeRouteType, index: number) => {
                          return (
                            <div key={index}>
                              <CustomMenuItem
                                userData={getUserData()}
                                scopeRoute={scopeRoute}
                                state={state}
                                alertCount={alertCount}
                              />

                              {state.openSidebar &&
                                scopeRoute.subMenus !== undefined &&
                                scopeRoute.subMenus?.map((subMenuItem) => {
                                  return (
                                    <div className="sub-menu-container">
                                      <SubMenuItem
                                        userData={getUserData()}
                                        scopeRoute={subMenuItem}
                                        state={state}
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                }
              )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
