import { lazy } from "react";

// project imports
import { Loadable, AuthLayout } from "../../layout";
import { RoutePath } from "../routesPathConfig";
import RegisterPassKey from "../../pages/Auth/RegisterPassKey/RegisterPassKey";

const Login = Loadable(
  lazy(() =>
    import("../../pages/Auth/Login").then((module) => ({
      default: module.Login,
    }))
  )
);

const VendorRegistration = Loadable(
  lazy(() =>
    import("../../pages/Auth/VendorRegistration").then((module) => ({
      default: module.VendorRegistration,
    }))
  )
);

const ResetPassword = Loadable(
  lazy(() =>
    import("../../pages/Auth").then((module) => ({
      default: module.ResetPassword,
    }))
  )
);

const ForgotPassword = Loadable(
  lazy(() =>
    import("../../pages/Auth/ForgotPassword").then((module) => ({
      default: module.ForgotPassword,
    }))
  )
);

const ForgetPasswordConfirmCode = Loadable(
  lazy(() =>
    import("../../pages/Auth/ForgetPasswordConfirmCode").then((module) => ({
      default: module.ForgetPasswordConfirmCode,
    }))
  )
);

const NotFoundPage = Loadable(
  lazy(() =>
    import("../../pages/Auth").then((module) => ({
      default: module.NotFoundPage,
    }))
  )
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

export const AuthRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: RoutePath.APP_LANDING_PAGE,
      element: <Login />,
    },
    {
      path: RoutePath.VENDOR_REGISTRATION,
      element: <VendorRegistration />,
    },
    {
      path: RoutePath.FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: RoutePath.FORGET_PASSWORD_CONFIRM_CODE,
      element: <ForgetPasswordConfirmCode />,
    },
    {
      path: RoutePath.REGISTER_PASSKEY,
      element: <RegisterPassKey />,
    },
    {
      path: RoutePath.RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ],
};
