import React from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { ISearchInputProps } from "./SearchInput.types";
import "./SearchInput.scss";

export const SearchInput: React.FC<ISearchInputProps> = ({
  value,
  onChange,
  className,
  ...inputProps
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={`search-container ${className}`}>
      <div className="search-icon-wrapper">
        <SearchIcon />
      </div>
      <InputBase
        className="styled-input-base"
        placeholder={inputProps.placeholder || "Search..."}
        inputProps={{ "aria-label": "search" }}
        sx={{ paddingTop: "3px" }}
        value={value}
        onChange={handleChange}
        {...inputProps}
      />
    </div>
  );
};
