/**
 * Converts an ISO date string to a custom format.
 *
 * @param {string} isoDate - The ISO date string to be formatted.
 * @return {string} The formatted date string.
 */
export const formatDateToCustomFormat = (isoDate: string): string => {
  if (!isoDate) return "";

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const formattedDate = new Date(isoDate).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

export const addDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const formattedTime = (time: string) => {
  //console.log("formattedTime", time, time.replace(/:\d{2} /, " "));
  return time.replace(/:\d{2} /, " ");
}; // Removes seconds
