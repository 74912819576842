import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Internal
import "./MGInputBox.scss";

type MGInputBoxProps = {
  containerClassName?: string;
  handlePhoneChange?: (data: string) => void;
  //value?: string | number;
};

export const MGInputBox = ({
  variant,
  className,
  label,
  required,
  containerClassName,
  handlePhoneChange,
  type,
  value,
  ...rest
}: TextFieldProps & MGInputBoxProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const getEndAdornment = () => {
    if (type === "password") {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            onMouseDown={(e) => e.preventDefault()}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    }
  };

  return (
    <div className={`input-box-container ${containerClassName}`}>
      {label && (
        <label className="label-style">
          {label} {required && <span className="mandatory-field">*</span>}
        </label>
      )}
      {type === "tel" ? (
        <PhoneInput
          inputClassName={`mg-input-box phone-number-input-text ${
            className ? className : ""
          }`}
          defaultCountry="us"
          value={(value as string) || ""}
          onChange={handlePhoneChange}
        />
      ) : (
        <TextField
          className={`mg-input-box ${className ? className : ""}`}
          variant={variant}
          inputProps={{ "data-type": type }}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          value={value}
          InputProps={{ endAdornment: getEndAdornment() }}
          {...rest}
        />
      )}
    </div>
  );
};
