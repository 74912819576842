import { Link, useLocation } from "react-router-dom";
import { ScopeRouteType } from "../../routes";

type MenuItemProps = {
  scopeRoute: ScopeRouteType;
  userData: any;
  state: any;
  alertCount?: any;
};
export const SubMenuItem = ({
  scopeRoute,
  userData,
  state,
  alertCount,
}: MenuItemProps) => {
  const location = useLocation();
  const pathName = location.pathname;
  console.log("scopeRoute", scopeRoute, pathName);
  return (
    <li
      className={`nav-link ${
        scopeRoute.notvisible !== undefined &&
        scopeRoute.notvisible.includes(userData?.["custom:vendorType"])
          ? "hide"
          : ""
      }`}
      key={scopeRoute.path}
    >
      <Link
        to={scopeRoute.path}
        className={`left-section-text-color ${
          pathName === scopeRoute.path ? "selected-link" : ""
        }`}
      >
        <span
          className={pathName === scopeRoute.path ? "selected-icon" : "icon"}
        >
          {scopeRoute.icon ? scopeRoute.icon : ""}
        </span>
        <span className="text nav-text">
          {scopeRoute.label ? scopeRoute.label : ""}
        </span>
        {scopeRoute.count && scopeRoute.label === "Alerts" && (
          <span className="text count-text">
            {alertCount?.length || scopeRoute.count}
          </span>
        )}

        {scopeRoute.count && scopeRoute.label === "Vendor requests" && (
          <span className="text count-text">
            {state?.vendorRequestCount || scopeRoute.count}
          </span>
        )}
      </Link>
    </li>
  );
};
