import { useContext, useMemo } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Internal
import { API } from "../../../constants";
import { useGetService } from "../../../hooks";
import { GlobalContextData } from "../../../context";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";

export const InvoiceList = () => {
  const navigate = useNavigate();

  const { state } = useContext(GlobalContextData);

  const { response, isLoading } = useGetService(
    `${API.INVOICE.GET}?vendorId=${state.userInfo["cognito:username"]}`
  );

  const handleView = (params: any) => {
    navigate(`/invoicing/${params.id}`);
  };

  console.log("response", response);
  const columnHeaderData = useMemo(() => {
    return [
      {
        field: "invoiceNumber",
        headerName: "Invoice number",
        flex: 1,
      },
      {
        field: "amountDue",
        headerName: "Total amount due",
        flex: 1,
        valueFormatter: (value?: number) => {
          if (value == null) {
            return "";
          }
          return `$ ${value.toLocaleString()}`;
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Chip label={params.value} sx={{ paddingTop: "3px" }} />
        ),
      },
      {
        field: "createdOn",
        headerName: "Created on",
        flex: 1,
        valueGetter: (value: any, row: any) => {
          return moment(row.createdOn).format("MM/DD/YYYY");
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Delete"
            onClick={() => handleView(params)}
          />,
        ],
      },
    ] as GridColDef[];
  }, []);

  return (
    <section className="invoicing-details-container">
      <DataGrid
        rows={response?.data || []}
        sx={{
          height:
            response?.data?.length === 0 || response?.data === undefined
              ? 200
              : "auto",
          marginTop: 2,
        }}
        columns={columnHeaderData || []}
        loading={isLoading}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        hideFooter={[].length === 0}
      />
    </section>
  );
};
